.u-header-reset {
  margin: 0;
  padding: 0;
}

.u-header-expanded {
  overflow: hidden;
}

.u-header-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.u-header-nemo-fullwidth-patch {
  display: none;
  height: 72px;
}

/* ToDo: Remove after AEM template adaption (extract audi-header from nm-wrapper) */
@media (min-width: 1400px) {
  .nm-wrapper {
    overflow: initial;
    position: static;
  }

  .nm-header {
    position: static;
  }

  .nm-wrapper [data-navigation-items-amount][data-module="one-header"] {
    left: 0;
    position: absolute;
    width: 100%;
  }

  .nm-wrapper [data-navigation-items-amount][data-module="one-header"] + .u-header-nemo-fullwidth-patch {
    display: block;
  }
}

@media (min-width: 1920px) {
  [page-type=widescreen] .nm-wrapper [data-navigation-items-amount][data-module="one-header"] {
    left: 0;
    position: absolute;
    width: 100%;
  }

  [page-type=widescreen] .nm-wrapper [data-navigation-items-amount][data-module="one-header"] + .u-header-nemo-fullwidth-patch {
    display: block;
  }
}
